<template>
  <div class="info">
    <div class="cell">
      <div class="container">
        <span>真实姓名</span>
        <input
          type="text"
          v-model="form.name"
          maxlength="10"
          placeholder="请输入真实姓名"
        />
      </div>
    </div>
    <div class="cell">
      <div class="container">
        <span>国家/地区</span>
        <input
          @click="seleteCountry"
          type="text"
          v-model="Zone"
          placeholder="请选择国家"
        />
      </div>
    </div>
    <div class="cell">
      <div class="container">
        <span>手机号码</span>
        <input
          type="text"
          v-model="form.tel"
          maxlength="11"
          placeholder="请输入手机号码"
        />
      </div>
    </div>
    <div class="cell">
      <div class="container">
        <span>身份证号</span>
        <input
          type="text"
          v-model="form.cert_num"
          maxlength="18"
          placeholder="请输入身份证号"
        />
      </div>
    </div>

    <div class="btn-bottom">
      <button class="btn-black" @click="save">保存</button>
    </div>

    <mt-popup v-model="popupVisible" position="bottom">
      <mt-picker
        :slots="slots"
        value-key="chinese_name"
        @change="onValuesChange"
      ></mt-picker>
    </mt-popup>
  </div>
</template>

<script>
import { Toast } from "mint-ui";
import { certUpdate, certEdit, Zone } from "@api/public";
export default {
  name: "person-list",
  data() {
    return {
      popupVisible: false,
      Zone: "",
      zone_id: "",
      id: "",
      sid: "",
      cert_id: "",
      isdefault: "",
      ZoneList: {},
      form: {
        name: "",
        tel: "",
        cert_num: "",
      },
      slots: [
        {
          values: [],
          textAlign: "center",
        },
      ],
    };
  },
  created() {
    if (this.$route.query) {
      this.id = this.$route.query.id;
      this.sid = this.$route.query.sid;
      this.cert_id = this.$route.query.cert_id;
      this.isdefault = this.$route.query.isdefault;
    }
    this.getZone();
  },
  methods: {
    // 获取国家地区
    getZone() {
      let that = this;
      Zone().then((res) => {
        that.$set(that, "ZoneList", res.data);
        that.$set(that.slots[0], "values", res.data);

        if (that.cert_id) {
          that.getEditData();
        }
      });
    },

    // 获取编辑信息
    getEditData() {
      certEdit(this.cert_id).then((res) => {
        let that = this,
          data = res.data.data,
          form = that.form;

        form.name = data.name;
        form.cert_num = data.cert_num;
        form.tel = data.tel;
        that.ZoneList.some((item) => {
          if (item.id === data.zone_id) {
            that.Zone = item.chinese_name;
          }
        });
      });
    },

    save() {
      let that = this,
        form = that.form;

      if (!/^[\u4E00-\u9FA5]{2,10}$/.test(form.name)) {
        Toast({ message: "姓名必填且为2-10位的中文字符" });
        return false;
      } else if (!/^1[3456789]\d{9}$/.test(form.tel)) {
        Toast({ message: "请输入正确的手机号" });
        return false;
      } else if (
        !form.cert_num ||
        !/^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/.test(
          form.cert_num
        )
      ) {
        Toast({ message: "请输入正确的身份证号" });
        return false;
      } else {
        let params = {
          ...that.form,
          zone_id: that.zone_id,
          isdefault: that.isdefault == 1 ? 1 : 0,
          cert_id: that.cert_id ? that.cert_id : "",
        };
        // 新增编辑上课人
        certUpdate(params)
          .then((res) => {
            that.$dialog.success(res.msg);
            if (this.id) {
              that.$router.push({
                path: `/lesson/personList`,
                query: { id: this.id, sid: this.sid },
              });
            } else {
              that.$router.push({
                path: `/user/MyListen`,
              });
            }
          })
          .catch((err) => {
            that.$dialog.error(err.msg);
          });
      }
    },

    seleteCountry() {
      this.popupVisible = true;
    },

    onValuesChange(picker, values) {
      if (values[0]) {
        this.Zone = values[0].chinese_name;
        this.zone_id = values[0].id;
      }
      this.popupVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  .mint-popup {
    width: 100%;
  }
}
</style>
